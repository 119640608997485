/*
-----------------------------
Home
-----------------------------
*/
.main-banner {
	background: $gris;
	position: relative;
	overflow: hidden;
	padding: 150px 0 100px 0;

	&::before {
		content: "";
		display: block;
		width: 37%;
		height: 100%;
		background-color: $azul2;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 0;
	}

	.contw {
		display: flex;
		flex-direction: row;
		align-items: center;
		.info {
			width: 50%;
			position: relative;
			z-index: 10;
			.caption {
				padding-right: 30px;
				.titulo-sm {
					margin-bottom: 0.7em;
					display: block;
				}
				h1 {
					font-family: $mono;
					font-size: 40px;
					letter-spacing: 1px;
					color: $azul;
					margin-top: 20px;
				}
			}
		}
		.banner {
			width: 50%;
			position: relative;
			z-index: 10;
			video {
				width: calc(100% + 25%);
				height: auto;
			}
		}
	}
	@media #{$break-md} {
		background: $azul2;
		&::before {
			width: 100%;
			background-color: $azul2;
			z-index: 5;
			opacity: 0.75;
		}
		.contw {
			.info {
				width: 75%;
				.caption {
					.titulo-sm,
					h1,
					p {
						color: #fff;
					}
				}
			}
			.banner {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				video {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
	}
	@media #{$break-sm} {
		.contw {
			.info {
				width: 90%;
				.caption {
					h1 {
						font-size: 32px;
					}
				}
			}
		}
	}
	@media #{$break-xs} {
		.contw {
			.info {
				width: 100%;
				.caption {
					h1 {
						font-size: 28px;
					}
				}
			}
		}
	}
}

.clientes-logos {
	background-color: $gris;
	padding: 50px 0;
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: row;
		align-items: stretch;
		li {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 14.28%;
			border-right: 1px solid darken($gris, 5%);
			&:last-child {
				border: none;
			}
			figure {
				margin: 0;
				padding: 0 10px;
			}
		}
	}
	@media #{$break-md} {
		padding-bottom: 20px;
		ul {
			flex-wrap: wrap;
			justify-content: center;
			li {
				width: 20%;
				margin-bottom: 30px;
			}
		}
	}
	@media #{$break-sm} {
		ul {
			li {
				width: 25%;
			}
		}
	}
	@media #{$break-xs} {
		ul {
			li {
				width: 33.333333%;
			}
		}
	}
	@media #{$break-480} {
		ul {
			li {
				&:nth-child(1),
				&:nth-child(6) {
					figure {
						img {
							width: 50px;
						}
					}
				}
			}
		}
	}
}

.acerca {
	padding: 100px 0 0 0;
	position: relative;
	overflow: hidden;
	.contw {
		position: relative;
		z-index: 10;
	}
	.mision-vision {
		list-style-type: none;
		margin: 40px 0 0 0;
		padding: 0;
		display: flex;
		flex-direction: row;
		align-items: stretch;
		border-top: 1px solid darken($gris, 10%);
		li {
			width: 50%;
			position: relative;
			.inner {
				padding: 50px 40px 20px 40px;
			}
			&:first-child {
				border-right: 1px solid darken($gris, 10%);
			}
		}
	}
	.back {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		&::before {
			content: "";
			display: block;
			width: 70%;
			height: 100%;
			background-color: #fff;
			z-index: 2;
			position: absolute;
			top: 0;
			left: 0;
		}
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			object-fit: cover;
			opacity: 0.1;
			transform: scaleX(-1);
		}
	}
	@media #{$break-md} {
		.mision-vision {
			li {
				.inner {
					padding: 50px 20px 20px 20px;
				}
			}
		}
	}
	@media #{$break-md} {
		figure {
			width: 100%;
			padding: 0;
			display: flex;
			justify-content: flex-end;
			img {
				max-width: 50%;
				height: auto;
				display: block;
				margin: 0 0 0 auto;
				transform: translateX(15px);
			}
		}
		.back {
			&::before {
				width: 100%;
				height: calc(100% - 300px);
				top: 0;
				left: 0;
			}
		}
	}
	@media #{$break-xs} {
		figure {
			img {
				max-width: 75%;
			}
		}
		.back {
			&::before {
				height: calc(100% - 220px);
			}
		}
		.mision-vision {
			flex-direction: column;
			li {
				width: 100%;
				.inner {
					padding: 0;
					margin: 30px 0 0 0;
				}
				&:first-child {
					border: none;
				}
			}
		}
	}
}

.servicios {
	background-color: $gris;
	padding: 100px 0 50px;
	.feats {
		list-style-type: none;
		margin: 25px 0 0 0;
		padding: 0;
		display: flex;
		flex-direction: row;
		align-items: stretch;
		justify-content: center;
		&--item {
			width: 25%;
			display: flex;
			flex-direction: row;
			align-items: stretch;
			position: relative;
			&::before {
				content: "";
				display: block;
				width: 1px;
				height: 100%;
				background-color: darken($gris, 10%);
				position: absolute;
				top: 0;
				right: 0;
			}
			figure {
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				margin: 0;
				padding: 0 15px;
				.icon {
					width: 80px;
					display: block;
				}
				figcaption {
					width: calc(100% - 80px);
					text-align: left;
					font-family: $semibold;
					color: $azul;
				}
			}
			&:last-child {
				&::before {
					display: none;
				}
			}
		}
	}
	@media #{$break-md} {
		.feats {
			flex-wrap: wrap;
			&--item {
				width: 50%;
				margin-bottom: 25px;
				&::before {
					display: none;
				}
			}
		}
	}
	@media #{$break-480} {
		.feats {
			flex-direction: column;
			&--item {
				width: 100%;
				margin-bottom: 25px;
			}
		}
	}
}

.servicios-blocks {
	background: $gris;
	.grid {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		.sb-item {
			width: 16.666666%;
		}
	}
	@media #{$break-lg} {
		.grid {
			flex-wrap: wrap;
			.sb-item {
				width: 33.33333333%;
				min-height: 500px;
			}
		}
	}
	@media #{$break-sm} {
		.grid {
			.sb-item {
				width: 50%;
				min-height: inherit;
				.caption {
					padding: 50px 20px 100px;
				}
			}
		}
	}
	@media #{$break-480} {
		.grid {
			.sb-item {
				.caption {
					h3 {
						font-size: 18px;
					}
				}
			}
		}
	}
}

.sb-item {
	position: relative;
	overflow: hidden;
	min-height: 600px;
	.caption {
		position: relative;
		z-index: 10;
		padding: 40px 20px 20px;
		color: #fff;
		small {
			display: block;
			text-align: right;
			font-family: $semibold;
			margin-bottom: 40px;
		}
		h3 {
			font-family: $bold;
			margin: 0 0 30px;
		}
		.btn {
			border: 1px solid #fff;
			color: #fff;
			border-radius: 50px;
			padding: 1em 1.25em;
			&:hover,
			&:focus {
				background-color: darken($azul, 15%);
			}
		}
	}
	.pic {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		&::before {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			background-color: #000;
			opacity: 0.5;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 5;
			transition: all 0.3s ease-out;
		}
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			z-index: 1;
		}
	}
	&:hover {
		.pic {
			&::before {
				background-color: $azul;
				opacity: 0.8;
			}
		}
	}
}

.modal-contenido {
	ul {
		li {
			margin-bottom: 1em;
		}
	}
	.swiper-container {
		width: 100%;
		position: relative;
		.swiper-slide {
			width: 100%;
			overflow: hidden;
			img {
				width: 100%;
				height: auto;
				@supports (aspect-ratio: 1) {
					aspect-ratio: 1;
					object-fit: cover;
				}
			}
		}
		.controls {
			position: absolute;
			top: 50%;
			z-index: 10;
			transform: translateY(-50%);
			&.swiper-prev {
				left: 10px;
			}
			&.swiper-next {
				right: 10px;
			}
		}
	}
}

.portafolio {
	padding: 100px 0;
	max-width: 1400px;
	margin: auto;
	.portafolio-grid {
		margin-top: 20px;
		padding: 0;
	}
	.portafolio-item {
		width: calc(33.33333% - 30px);
		margin-bottom: 50px;
		@media #{$break-lg} {
			width: calc(50% - 30px);
		}
		@media #{$break-xs} {
			width: calc(100% - 30px);
		}
	}
}

.portafolio-item {
	.thumb {
		position: relative;
		img {
			width: 100%;
			height: auto;
			position: relative;
			z-index: 1;
		}
		.titulo {
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 10;
			.heading-s1 {
				margin: 0;
				padding: 0;
				span {
					display: inline-block;
					background-color: #fff;
					padding: 0.75em 1.15em;
					max-width: 95%;
				}
			}
		}
	}
	.caption {
		ul {
			li {
				line-height: 155%;
				margin-top: 0.85em;
			}
		}
	}
}

.contacto {
	background-color: $azul2;
	padding: 100px 0;
	.info {
		color: #fff;
		margin-bottom: 50px;
		.lead {
			font-family: $bold;
			font-size: 48px;
			line-height: 1.2em;
			margin: 10px 0 50px;
		}
		.heading-s1 {
			color: #fff;
		}
		.btn {
			color: #fff;
			font-family: $regular;
			svg {
				margin-right: 10px;
			}
		}
	}
	.formulario {
		.lead {
			color: #fff;
			line-height: 1.35em;
			margin: 0 0 30px;
		}
	}
}

.forma {
	.form-floating,
	.form-group {
		label {
			color: #fff;
			font-size: 13px;
			font-family: $bold;
			display: block;
			margin-bottom: 0.5em;
		}
		.form-control,
		.form-select {
			background-color: $azul2;
			border: 1px solid rgba(255, 255, 255, 0.2);
			color: #fff;
			&::placeholder {
				color: #fff;
				opacity: 0.25;
			}
		}
	}
}

.gracias {
	width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $gris;
}
