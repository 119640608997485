/*
-----------------------------
Header
-----------------------------
*/
.main-header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 99;
	padding: 20px 0;
	.contw {
		display: flex;
		flex-direction: row;
		align-items: center;
		.nav {
			ul {
				display: flex;
				flex-direction: row;
				align-items: center;
				list-style-type: none;
				margin: 0 0 0 10px;
				padding: 0;
				li {
					a {
						display: block;
						font-family: $bold;
						font-size: 13px;
						letter-spacing: 1px;
						color: $azul2;
						padding: 1em;
						text-decoration: none;
						&:hover {
							color: $azul;
						}
					}
				}
			}
		}
		.info {
			margin-left: auto;
		}
	}
	@media #{$break-md} {
		.contw {
			.nav {
				ul {
					li {
						a {
							color: #fff;
							opacity: 0.85;
							&:hover {
								color: #fff;
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
	@media #{$break-sm} {
		.contw {
			.nav {
				display: none;
			}
			.info {
				.btn-solid {
					padding: 10px 15px;
					font-size: 11px;
				}
			}
		}
	}
	@media #{$break-xs} {
		.contw {
			.info {
				.btn-solid {
					padding: 10px;
					font-size: 9px;
				}
			}
		}
	}
}

/*
-----------------------------
Footer
-----------------------------
*/
.main-footer {
	background-color: $gris;
	padding: 70px 0 40px 0;
	.logo-brand {
		border-bottom: 1px solid transparentize($fontcolor, 0.85);
		padding-bottom: 40px;
		margin-bottom: 40px;
	}
	.info {
		p {
			font-size: 14px;
			margin-top: 10px;
		}
		.btn-link {
			font-family: $regular;
			color: $fontcolor;
			padding-left: 0;
			text-decoration: none;
			&:hover {
				color: $azul;
			}
		}
	}
	@media #{$break-480} {
		.logo-brand,
		.info {
			.heading-s1 {
				font-size: 16px;
			}
		}
	}
}
