/*
-----------------------------
General
-----------------------------
*/

body {
	font-size: 16px;
	font-family: $regular;
	overflow-x: hidden;
	color: $fontcolor;
}

p,
blockquote {
	line-height: 155%;
	strong {
		font-family: $bold;
	}
}

.contw {
	width: 100%;
	max-width: 1140px;
	margin: 0 auto;
	padding: 0 15px;
}

.titulo-sm {
	font-family: $semibold;
	font-size: 13px;
	line-height: 16px;
	letter-spacing: 3px;
	text-transform: uppercase;
	&.azul {
		span {
			background-color: $azul;
			color: #fff;
			display: inline-block;
			padding: 5px 5px 3px;
			line-height: 1em;
		}
	}
}

.btn {
	font-family: $bold;
	font-size: 13px;
	letter-spacing: 1px;
	&.btn-solid {
		background-color: $azul;
		color: #fff;
		border-radius: 50px;
		padding: 1.3em 1.7em;
		&:hover,
		&:focus {
			background-color: lighten($azul, 5%);
		}
	}
}

.lead {
	font-size: 30px;
	@media #{$break-md} {
		font-size: 26px;
	}
	@media #{$break-sm} {
		font-size: 22px;
	}
	@media #{$break-xs} {
		font-size: 20px;
	}
}

.heading-s1 {
	font-family: $bold;
	font-size: 24px;
	color: $azul;
}
