/*
-----------------------------
Fonts
-----------------------------
*/
@font-face {
	font-family: "Graphik Bold";
	src: url("../fonts/Graphik-Bold.woff2") format("woff2"),
		url("../fonts/Graphik-Bold.woff") format("woff"),
		url("../fonts/Graphik-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Graphik Semibold";
	src: url("../fonts/Graphik-Semibold.woff2") format("woff2"),
		url("../fonts/Graphik-Semibold.woff") format("woff"),
		url("../fonts/Graphik-Semibold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Graphik Light";
	src: url("../fonts/Graphik-Light.woff2") format("woff2"),
		url("../fonts/Graphik-Light.woff") format("woff"),
		url("../fonts/Graphik-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Graphik Regular";
	src: url("../fonts/Graphik-Regular.woff2") format("woff2"),
		url("../fonts/Graphik-Regular.woff") format("woff"),
		url("../fonts/Graphik-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Computo monospaceregular";
	src: url("../fonts/computo-monospace-webfont.woff2") format("woff2"),
		url("../fonts/computo-monospace-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

$bold: "Graphik Bold", Helvetica, Arial, sans-serif;
$semibold: "Graphik Semibold", Helvetica, Arial, sans-serif;
$light: "Graphik Light", Helvetica, Arial, sans-serif;
$regular: "Graphik Regular", Helvetica, Arial, sans-serif;
$mono: "Computo monospaceregular", Courier, monospace;

/*
-----------------------------
Colores
-----------------------------
*/
$fontcolor: #23262b;
$azul: #0d49d4;
$azul2: #01016a;
$gris: #f3f4f5;
$negro: #000;

/*
-----------------------------
Media queries
-----------------------------
*/
$break-xl: "(max-width: 1399.98px)";
$break-lg: "(max-width: 1199.98px)";
$break-md: "(max-width: 991.98px)";
$break-sm: "(max-width: 767.98px)";
$break-xs: "(max-width: 575.98px)";
$break-480: "(max-width: 480px)";
$break-320: "(max-width: 320px)";
